import Link from 'next/link';

interface BlueCTALinkProps {
  buttonLink?: string;
  buttonText?: string;
}

const BlueCTALink = ({ buttonLink, buttonText }: BlueCTALinkProps) => {
  return (
    <Link
      className="mx-auto rounded border border-teal-900 bg-arctic-900 px-4 py-2 text-center text-xs font-semibold uppercase tracking-widest text-teal-900 shadow-lg hover:bg-arctic-600 active:bg-arctic-900"
      href={buttonLink || ''}
      target="_blank"
    >
      {buttonText}
    </Link>
  );
};

export default BlueCTALink;
